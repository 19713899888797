import {formatToDatetime} from "@/common/date_extension";

class User {
    private email: string;
    private name: string;
    private phone: string;
    private birthday: string;
    private gender: string;
    private readonly role: string;
    private balance: number;
    private pinball: number;
    private bonus: number;
    private bonusExpireTime: number;
    private readonly lineID: string;

    public constructor(
        email: string, name: string, phone: string, birthday: string,
        gender: string, role: string, balance: number, pinball: number,
        bonus: number, bonusExpireTime: number, lineID: string
    ) {
        this.email = email;
        this.name = name;
        this.phone = phone;
        this.birthday = birthday;
        this.gender = gender;
        this.role = role;
        this.balance = balance;
        this.pinball = pinball;
        this.bonus = bonus;
        this.bonusExpireTime = bonusExpireTime;
        this.lineID = lineID;
    }

    public static createEmpty(): User {
        return new User("", "", "", "", "", "", 0, 0, 0, 0, "")
    }

    public toJson() {
        return JSON.stringify({
            name: this.name,
            phone: this.phone,
            birthday: this.birthday,
            email: this.email,
            gender: this.gender,
            role: this.role,
            balance: this.balance,
            pinball: this.pinball,
            bonus: this.bonus,
            bonus_expire_time: this.bonusExpireTime,
            line_id: this.lineID
        });
    }

    public static fromJson(data: any) {
        return new User(
            data.email,
            data.name,
            data.phone,
            data.birthday,
            data.gender,
            data.role,
            data.balance,
            data.pinball,
            data.bonus,
            data.bonus_expire_time,
            data.line_id
        );
    }

    public updateBonus(bonus: number) {
        this.bonus = bonus;

        if (bonus === 0) {
            this.bonusExpireTime = 0;
        }
    }

    public isNotRegistered() {
        return this.phone === "";
    }

    public updateProfile(name: string, email: string, birthday: string, gender: string) {
        this.name = name;
        this.email = email;
        this.birthday = birthday;
        this.gender = gender;
    }

    public update(user: User) {
        this.name = user.getName();
        this.email = user.getEmail();
        this.phone = user.getPhone();
        this.birthday = user.getBirthday();
        this.gender = user.getGender();
        this.pinball = user.getPinball();
        this.bonus = user.getBonus();
        this.balance = user.getBalance();
        this.bonusExpireTime = user.getBonusExpireTime();
    }

    //region public get

    public getName() {
        return this.name;
    }

    public getEmailInfo() {
        return this.email === "" ? "無" : this.email;
    }

    public getEmail() {
        return this.email;
    }

    public getPhone() {
        return this.phone;
    }

    public getBirthdayInfo() {
        return this.birthday === "" ? "無" : this.birthday;
    }

    public getBirthday() {
        return this.birthday;
    }

    public getGenderInfo() {
        return this.gender === "" ? "無" : this.gender;
    }

    public getGender() {
        return this.gender;
    }

    public getPinball() {
        return this.pinball;
    }

    public getBalance() {
        return this.balance;
    }

    public getBonus() {
        return this.bonus;
    }

    public getBonusExpireDate() {
        return this.bonusExpireTime === 0 ? "無" : formatToDatetime(this.bonusExpireTime);
    }

    public getBonusExpireTime() {
        return this.bonusExpireTime;
    }

    public getBonusStatusColor() {
        const isExpired = Date.now() > this.bonusExpireTime * 1000;

        return isExpired && this.bonusExpireTime !== 0 ? "red" : "black";
    }

    public getRole() {
        return this.role;
    }

    public isUser() {
        return this.role === "user";
    }

    public isManager(): boolean {
        return this.role === "manager";
    }

    public isAdmin(): boolean {
        return this.role === "admin";
    }

    public getLineID() {
        return this.lineID;
    }

    public hasBirthday() {
        return this.birthday !== "";
    }

    //endregion
}

export default User;